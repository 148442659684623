var KeypressHandler = /** @class */ (function () {
    function KeypressHandler() {
        this.handlers = {};
        this.currentLocationHandler = "default";
        document.addEventListener("keydown", this.handleKeypress.bind(this));
    }
    KeypressHandler.getInstance = function () {
        if (!KeypressHandler.instance) {
            KeypressHandler.instance = new KeypressHandler();
        }
        return KeypressHandler.instance;
    };
    KeypressHandler.prototype.registerHandler = function (handler, currentLocationHandler) {
        this.handlers[currentLocationHandler] = handler;
    };
    KeypressHandler.prototype.setCurrentLocationHandler = function (locationHandler) {
        this.currentLocationHandler = locationHandler;
    };
    KeypressHandler.prototype.handleKeypress = function (event) {
        var handler = this.handlers[this.currentLocationHandler];
        if (handler) {
            handler(event);
        }
    };
    return KeypressHandler;
}());
export default KeypressHandler.getInstance();
