import axios from "axios";
var request_GET = function (url, parameters) {
    var config = {};
    if (parameters) {
        config.params = parameters;
    }
    return axios.get(url, config);
};
var apiFactory = function (baseURL) { return ({
    request_get: function (url, parameters) {
        return request_GET(baseURL + url, parameters);
    }
}); };
var api = apiFactory("https://cd-static.bamgrid.com/dp-117731241344/");
export default api;
