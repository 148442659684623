import api from "./api";
var getList = function () {
    return api.request_get("home.json", {});
};
var getSetsByRefId = function (refId) {
    return api.request_get("sets/".concat(refId, ".json"), {});
};
var disneyApi = {
    getList: getList,
    getSetsByRefId: getSetsByRefId
};
export default disneyApi;
