var NavigationController = /** @class */ (function () {
    function NavigationController() {
        this.listeners = [];
        this.currentLocation = "MainPage";
    }
    NavigationController.getInstance = function () {
        if (!NavigationController.instance) {
            NavigationController.instance = new NavigationController();
        }
        return NavigationController.instance;
    };
    NavigationController.prototype.notifyListeners = function (location) {
        this.listeners.forEach(function (listener) { return listener(location); });
    };
    NavigationController.prototype.addLocationChangeListener = function (listener) {
        this.listeners.push(listener);
    };
    NavigationController.prototype.setCurrentLocation = function (location) {
        this.currentLocation = location;
        this.notifyListeners(location);
    };
    NavigationController.prototype.getCurrentLocation = function () {
        return this.currentLocation;
    };
    return NavigationController;
}());
export default NavigationController.getInstance();
