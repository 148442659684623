var Button = function (title, id) {
    var button = document.createElement("button");
    button.className = "button-container selectable";
    button.id = id;
    var buttonTitle = document.createElement("p");
    buttonTitle.className = "button-container-title";
    buttonTitle.innerHTML = title;
    button.appendChild(buttonTitle);
    return button;
};
export default Button;
