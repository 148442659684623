var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import disneyApi from "../api/disneyApi";
import SplashLayer, { removeSplashLayer } from "./SplashLayer";
import HeaderTitle from "../components/HeaderTitle";
import Shelf from "../components/Shelf";
import ShelfTitle from "../components/ShelfTitle";
import ShelfItems from "../components/ShelfItems";
import NavigationController from "../navigation/NavigationController";
import GlobalState from "../state/GlobalState";
// MARK: - Current selected shelf / item
var selectedShelf = 0;
var selectedIndexShelf;
// MARK: - Data
var isLoadingData = false;
var responseData = [];
var title = "";
// MARK: - VideoArt
var playVideoTimeout = null;
var currentlyPlayingVideo = null;
var MainPage = function (title, responseData) {
    var container = document.getElementById("home-container");
    if (container) {
        container.innerHTML = "";
        var headerTitle = HeaderTitle(title);
        container.appendChild(headerTitle);
        responseData.forEach(function (shelf) {
            container.appendChild(ShelfTitle(shelf));
            var listSliderContainer = Shelf(shelf);
            container.appendChild(listSliderContainer);
        });
    }
};
var initMainPage = function () {
    fetchHomeData();
};
var fetchHomeData = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, responseShelfContainers, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                SplashLayer();
                if (isLoadingData)
                    return [2 /*return*/];
                isLoadingData = true;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, 4, 5]);
                return [4 /*yield*/, disneyApi.getList()];
            case 2:
                response = _a.sent();
                responseShelfContainers = response.data.data.StandardCollection.containers;
                responseData = responseShelfContainers;
                title =
                    response.data.data.StandardCollection.text.title.full.collection.default
                        .content;
                selectedIndexShelf = new Array(responseData.length).fill(0);
                displayData();
                setTimeout(function () {
                    removeSplashLayer();
                }, 400);
                return [3 /*break*/, 5];
            case 3:
                error_1 = _a.sent();
                console.error(error_1);
                return [3 /*break*/, 5];
            case 4:
                isLoadingData = false;
                return [7 /*endfinally*/];
            case 5: return [2 /*return*/];
        }
    });
}); };
var fetchSetByRefId = function (shelfDiv, refId, refType) { return __awaiter(void 0, void 0, void 0, function () {
    var response, refTypeToUse, responseShelfItem, dataToUpdateIndex, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, disneyApi.getSetsByRefId(refId)];
            case 1:
                response = _a.sent();
                refTypeToUse = refType;
                if (refType === "BecauseYouSet") {
                    refTypeToUse = "CuratedSet";
                }
                responseShelfItem = response.data.data[refTypeToUse];
                dataToUpdateIndex = responseData.findIndex(function (shelf) {
                    return shelf.set.refId === refId;
                });
                if (dataToUpdateIndex) {
                    responseData[dataToUpdateIndex].set = responseShelfItem;
                }
                ShelfItems(responseShelfItem, shelfDiv);
                return [3 /*break*/, 3];
            case 2:
                error_2 = _a.sent();
                console.error(error_2);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
var displayData = function () {
    MainPage(title, responseData);
    updateSelectedShelf();
};
var updateSelectedShelf = function () {
    var shelves = document.querySelectorAll(".list-container");
    if (shelves.length === 0)
        return;
    selectedShelf = Math.max(0, Math.min(selectedShelf, shelves.length - 1));
    var nextSelectedShelfData = responseData[selectedShelf + 3];
    if (nextSelectedShelfData &&
        nextSelectedShelfData.set &&
        !nextSelectedShelfData.set.items &&
        nextSelectedShelfData.set.refId &&
        nextSelectedShelfData.set.refType) {
        fetchSetByRefId(shelves[selectedShelf + 3], nextSelectedShelfData.set.refId, nextSelectedShelfData.set.refType);
    }
    shelves.forEach(function (shelf, index) {
        if (index === selectedShelf) {
            shelf.classList.add("selected");
        }
        else {
            shelf.classList.remove("selected");
        }
    });
    updateSelectedIndex();
};
var resetSelectedShelf = function () {
    var shelves = document.querySelectorAll(".list-container");
    if (shelves.length === 0)
        return;
    var currentShelf = shelves[selectedShelf];
    var currentItems = currentShelf.querySelectorAll(".list-item");
    currentItems.forEach(function (item) {
        item.classList.remove("selected");
        pauseVideoItem(item);
    });
};
var updateSelectedIndex = function () {
    var shelves = document.querySelectorAll(".list-container");
    if (shelves.length === 0)
        return;
    var items = shelves[selectedShelf].querySelectorAll(".list-item");
    if (items.length === 0)
        return;
    if (selectedIndexShelf[selectedShelf] >= items.length) {
        selectedIndexShelf[selectedShelf] = items.length - 1;
    }
    else if (selectedIndexShelf[selectedShelf] < 0) {
        selectedIndexShelf[selectedShelf] = 0;
    }
    items.forEach(function (item, index) {
        if (index === selectedIndexShelf[selectedShelf]) {
            item.classList.add("selected");
            setTimeout(function () {
                item.scrollIntoView({
                    behavior: "auto",
                    block: "center",
                    inline: "center"
                });
            }, 50);
            if (playVideoTimeout) {
                clearTimeout(playVideoTimeout);
            }
            playVideoTimeout = setTimeout(function () {
                playVideoItem(item);
            }, 4000);
        }
        else {
            item.classList.remove("selected");
            pauseVideoItem(item);
        }
    });
};
var playVideoItem = function (item) {
    if (NavigationController.getCurrentLocation() !== "MainPage")
        return;
    var listItemVideo = item.querySelector(".list-item-video");
    var videoElement = listItemVideo;
    console.log(videoElement);
    if (listItemVideo && videoElement) {
        if (currentlyPlayingVideo && currentlyPlayingVideo !== videoElement) {
            currentlyPlayingVideo.pause();
            currentlyPlayingVideo.autoplay = false;
            currentlyPlayingVideo.classList.remove("list-item-video-visible");
        }
        videoElement.play().catch(function (error) {
            console.error("Error", error);
        });
        videoElement.autoplay = true;
        videoElement.classList.add("list-item-video-visible");
        currentlyPlayingVideo = videoElement;
    }
};
var pauseVideoItem = function (item) {
    var listItemVideo = item.querySelector(".list-item-video");
    var videoElement = listItemVideo;
    if (listItemVideo && videoElement) {
        if (!videoElement.paused) {
            videoElement.pause();
        }
        videoElement.autoplay = true;
        videoElement.classList.remove("list-item-video-visible");
    }
};
var nextShelfIndex = function () {
    var width = window.innerWidth;
    var nextIndex = selectedIndexShelf[selectedShelf + 1];
    var currentIndex = selectedIndexShelf[selectedShelf];
    var maxIndex = width > 1200 ? 2 : 1;
    if (nextIndex <= maxIndex) {
        return currentIndex <= maxIndex - 1 ? currentIndex : maxIndex;
    }
    else {
        return nextIndex;
    }
};
var previousShelfIndex = function () {
    var width = window.innerWidth;
    var minIdex = width > 1200 ? 2 : 1;
    if (selectedIndexShelf[selectedShelf] > 0 &&
        selectedIndexShelf[selectedShelf - 1] === 0) {
        return minIdex;
    }
    return selectedIndexShelf[selectedShelf - 1];
};
var handleKeyPress = function (event) {
    var _a, _b, _c;
    var shelves = document.querySelectorAll(".list-container");
    switch (event.key) {
        case "ArrowDown":
            event.preventDefault();
            resetSelectedShelf();
            selectedIndexShelf[selectedShelf + 1] = nextShelfIndex();
            selectedShelf = Math.min(selectedShelf + 1, shelves.length);
            updateSelectedShelf();
            break;
        case "ArrowRight":
            event.preventDefault();
            selectedIndexShelf[selectedShelf] += 1;
            updateSelectedIndex();
            break;
        case "ArrowLeft":
            event.preventDefault();
            selectedIndexShelf[selectedShelf] -= 1;
            updateSelectedIndex();
            break;
        case "ArrowUp":
            event.preventDefault();
            resetSelectedShelf();
            selectedIndexShelf[selectedShelf - 1] = previousShelfIndex();
            selectedShelf = selectedShelf - 1 < 0 ? 0 : selectedShelf - 1;
            updateSelectedShelf();
            break;
        case "Enter":
            event.preventDefault();
            if (!GlobalState.selectedItem) {
                var data = (_c = (_b = (_a = responseData[selectedShelf]) === null || _a === void 0 ? void 0 : _a.set) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c[selectedIndexShelf[selectedShelf]];
                if (data) {
                    GlobalState.selectedItem = data;
                    NavigationController.setCurrentLocation("DetailPage");
                }
            }
            break;
        default:
            break;
    }
};
export default MainPage;
export { handleKeyPress, initMainPage, displayData };
