import GridItem from "./GridItem";
var ShelfItems = function (data, shelfDiv) {
    var listContainer = document.createElement("ul");
    if (shelfDiv) {
        shelfDiv.innerHTML = "";
    }
    else {
        listContainer.className = "list-container";
    }
    if (data && data.items) {
        data.items.forEach(function (item) {
            var listItem = GridItem(item);
            if (shelfDiv) {
                shelfDiv.appendChild(listItem);
            }
            else {
                listContainer.appendChild(listItem);
            }
        });
    }
    else {
        var listItem = document.createElement("li");
        listItem.className = "list-item";
        if (shelfDiv) {
            shelfDiv.appendChild(listItem);
        }
        else {
            listContainer.appendChild(listItem);
        }
    }
    return listContainer;
};
export default ShelfItems;
