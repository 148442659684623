var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Button from "../components/Button";
import NavigationController from "../navigation/NavigationController";
import GlobalState from "../state/GlobalState";
import { getBackground, getTitleTreatment } from "../utils/dataUtils";
import { loadImage } from "../utils/imgUtils";
var selectedIndex = 0;
var DetailPage = function () { return __awaiter(void 0, void 0, void 0, function () {
    var container, homeContainer, selectedItem, div, backgroundDiv, overlayDiv, titleTreatment, img, backgroundFilterDiv, informationDiv, ratingDiv, releaseDiv, buttonsContainer, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                container = document.getElementById("detail-container");
                homeContainer = document.getElementById("home-container");
                if (homeContainer) {
                    homeContainer.classList.add("container-hidden");
                }
                selectedIndex = 0;
                if (!container) return [3 /*break*/, 4];
                container.innerHTML = "";
                selectedItem = GlobalState.selectedItem;
                if (!selectedItem)
                    return [2 /*return*/];
                div = document.createElement("div");
                div.classList.add("detail-page");
                backgroundDiv = document.createElement("div");
                backgroundDiv.classList.add("background-container");
                overlayDiv = document.createElement("div");
                overlayDiv.classList.add("overlay-container");
                titleTreatment = getTitleTreatment(selectedItem.image);
                if (titleTreatment) {
                    img = document.createElement("img");
                    img.src = titleTreatment;
                    img.classList.add("title-treatment-img");
                    overlayDiv.appendChild(img);
                }
                div.appendChild(backgroundDiv);
                div.appendChild(overlayDiv);
                backgroundFilterDiv = document.createElement("div");
                backgroundFilterDiv.classList.add("background-filter");
                backgroundDiv.appendChild(backgroundFilterDiv);
                informationDiv = document.createElement("div");
                informationDiv.classList.add("information-container");
                ratingDiv = document.createElement("div");
                ratingDiv.classList.add("tag-container");
                if (selectedItem.ratings && selectedItem.ratings.length === 1) {
                    ratingDiv.innerHTML = selectedItem.ratings[0].value;
                    informationDiv.appendChild(ratingDiv);
                }
                releaseDiv = document.createElement("div");
                releaseDiv.classList.add("tag-container");
                if (selectedItem.releases && selectedItem.releases.length === 1) {
                    releaseDiv.innerHTML = "".concat(selectedItem.releases[0].releaseYear);
                    informationDiv.appendChild(releaseDiv);
                }
                buttonsContainer = document.createElement("div");
                buttonsContainer.classList.add("buttons-container");
                overlayDiv.appendChild(informationDiv);
                if (selectedItem.videoArt && selectedItem.videoArt.length > 0) {
                    buttonsContainer.appendChild(Button("Play trailer", "play-button"));
                }
                buttonsContainer.appendChild(Button("Back", "back-button"));
                overlayDiv.appendChild(buttonsContainer);
                container.appendChild(div);
                updateSelectedIndex();
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, loadImageWithQuality(selectedItem, backgroundDiv)];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                error_1 = _a.sent();
                console.error(error_1);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
var updateSelectedIndex = function () {
    var buttons = document.querySelectorAll(".selectable");
    buttons.forEach(function (button, index) {
        if (index === selectedIndex) {
            button.classList.add("button-selected");
        }
        else {
            button.classList.remove("button-selected");
        }
    });
};
var loadImageWithQuality = function (selectedItem, backgroundDiv) { return __awaiter(void 0, void 0, void 0, function () {
    var lowQualityImg, highQualityImg, lowQualitySrc, highQualitySrc, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!backgroundDiv)
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 7]);
                lowQualitySrc = getBackground(selectedItem.image, 500);
                if (!lowQualitySrc) return [3 /*break*/, 3];
                return [4 /*yield*/, loadImage(lowQualitySrc, "background-image")];
            case 2:
                lowQualityImg = _a.sent();
                backgroundDiv.appendChild(lowQualityImg);
                _a.label = 3;
            case 3:
                highQualitySrc = getBackground(selectedItem.image, 1500);
                if (!highQualitySrc) return [3 /*break*/, 5];
                return [4 /*yield*/, loadImage(highQualitySrc, "background-image")];
            case 4:
                highQualityImg = _a.sent();
                if (lowQualityImg && lowQualityImg.parentNode) {
                    lowQualityImg.parentNode.removeChild(lowQualityImg);
                }
                backgroundDiv.appendChild(highQualityImg);
                _a.label = 5;
            case 5: return [3 /*break*/, 7];
            case 6:
                error_2 = _a.sent();
                console.error(error_2);
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}); };
var onClick = function (element) {
    switch (element.id) {
        case "play-button":
            NavigationController.setCurrentLocation("TrailerPage");
            break;
        case "back-button":
            NavigationController.setCurrentLocation("MainPage");
            break;
        default:
            break;
    }
};
var handleKeyPressDetail = function (event) {
    switch (event.key) {
        case "ArrowDown":
            event.preventDefault();
            break;
        case "ArrowRight":
            event.preventDefault();
            var buttons = document.querySelectorAll(".selectable");
            if (selectedIndex === buttons.length - 1)
                return;
            selectedIndex = selectedIndex + 1;
            updateSelectedIndex();
            break;
        case "ArrowLeft":
            event.preventDefault();
            if (selectedIndex === 0)
                return;
            selectedIndex = selectedIndex - 1;
            updateSelectedIndex();
            break;
        case "ArrowUp":
            event.preventDefault();
            break;
        case "Enter":
            event.preventDefault();
            var selectedItem = document.querySelector(".button-selected");
            if (selectedItem) {
                onClick(selectedItem);
            }
            break;
        case "Backspace":
            event.preventDefault();
            NavigationController.setCurrentLocation("MainPage");
            break;
        default:
            break;
    }
};
var hideDetailPage = function () {
    var container = document.getElementById("home-container");
    var detailContainer = document.getElementById("detail-container");
    if (detailContainer) {
        detailContainer.innerHTML = "";
    }
    if (container) {
        container.classList.remove("container-hidden");
    }
};
export default DetailPage;
export { handleKeyPressDetail, hideDetailPage };
