import KeypressHandler from "../KeypressHandler";
import DetailPage, { handleKeyPressDetail, hideDetailPage } from "./DetailPage";
import { handleKeyPress, initMainPage } from "./MainPage";
import NavigationController from "../navigation/NavigationController";
import GlobalState from "../state/GlobalState";
import TrailerPage, { handleKeyPressTrailer } from "./TrailerPage";
import { disableScroll } from "../utils/scrollUtils";
var init = function () {
    initKeypressHandler();
    initMainPage();
    disableScroll();
    KeypressHandler.setCurrentLocationHandler("MainPage");
};
var initKeypressHandler = function () {
    KeypressHandler.registerHandler(handleKeyPress, "MainPage");
    KeypressHandler.registerHandler(handleKeyPressDetail, "DetailPage");
    KeypressHandler.registerHandler(handleKeyPressTrailer, "TrailerPage");
};
var onLocationChange = function (newLocation) {
    switch (newLocation) {
        case "MainPage":
            GlobalState.selectedItem = null;
            hideDetailPage();
            KeypressHandler.setCurrentLocationHandler("MainPage");
            break;
        case "DetailPage":
            if (!GlobalState.selectedItem)
                return;
            DetailPage();
            KeypressHandler.setCurrentLocationHandler("DetailPage");
            break;
        case "TrailerPage":
            if (!GlobalState.selectedItem)
                return;
            TrailerPage();
            KeypressHandler.setCurrentLocationHandler("TrailerPage");
            break;
    }
};
NavigationController.addLocationChangeListener(onLocationChange);
document.addEventListener("DOMContentLoaded", init);
