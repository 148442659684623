import NavigationController from "../navigation/NavigationController";
import GlobalState from "../state/GlobalState";
var TrailerPage = function () {
    var container = document.getElementById("detail-container");
    if (container) {
        container.innerHTML = "";
        var selectedItem = GlobalState.selectedItem;
        if (!selectedItem)
            return;
        var div = document.createElement("div");
        div.classList.add("trailer-page");
        var video = document.createElement("video");
        video.className = "trailer-video";
        if (selectedItem.videoArt && selectedItem.videoArt.length > 0) {
            video.src = selectedItem.videoArt[0].mediaMetadata.urls[0].url;
            video.controls = true;
            video.preload = "none";
            video.currentTime = 0;
            video.autoplay = true;
            video.loop = true;
            video.muted = false;
        }
        div.appendChild(video);
        container.appendChild(div);
    }
};
var handleKeyPressTrailer = function (event) {
    switch (event.key) {
        case "Backspace":
            event.preventDefault();
            NavigationController.setCurrentLocation("DetailPage");
            break;
        default:
            break;
    }
};
export default TrailerPage;
export { handleKeyPressTrailer };
