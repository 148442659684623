import disneyPlusLogo from "../assets/disney-plus-logo.png";
var SplashLayer = function () {
    var container = document.getElementById("splash-container");
    if (container) {
        container.innerHTML = "";
        var div = document.createElement("div");
        div.className = "splash-layer";
        var img = document.createElement("img");
        img.className = "splash-layer-logo";
        img.src = disneyPlusLogo;
        div.appendChild(img);
        container.appendChild(div);
    }
};
var removeSplashLayer = function () {
    var splashLayer = document.querySelector(".splash-layer");
    if (splashLayer) {
        splashLayer.classList.add("splash-layer-animate-out");
        setTimeout(function () {
            splashLayer.remove();
        }, 2000);
    }
};
export default SplashLayer;
export { removeSplashLayer };
