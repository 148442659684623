import { getCoverImageUrl } from "../utils/dataUtils";
var GridItem = function (item) {
    var listItem = document.createElement("li");
    listItem.className = "list-item";
    var listItemImageContainer = document.createElement("div");
    listItemImageContainer.className = "list-item-image-container";
    var videoContainer = document.createElement("video");
    videoContainer.className = "list-item-video";
    if (item.videoArt && item.videoArt.length > 0) {
        videoContainer.src = item.videoArt[0].mediaMetadata.urls[0].url;
        videoContainer.controls = false;
        videoContainer.preload = "none";
        videoContainer.currentTime = 0;
        videoContainer.autoplay = false;
        videoContainer.loop = true;
        videoContainer.muted = true;
    }
    var imageUrl = getCoverImageUrl(item.image);
    if (imageUrl) {
        var imageContainer = document.createElement("div");
        imageContainer.className = "list-item-image-video-container";
        var img_1 = document.createElement("img");
        img_1.className = "list-item-image";
        img_1.src = imageUrl;
        img_1.onerror = function () {
            img_1.src = "";
        };
        imageContainer.appendChild(videoContainer);
        imageContainer.appendChild(img_1);
        listItemImageContainer.appendChild(imageContainer);
        listItem.appendChild(listItemImageContainer);
    }
    return listItem;
};
export default GridItem;
