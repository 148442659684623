var getTitleContent = function (itemText) {
    if (itemText) {
        if (itemText.set) {
            return itemText.set.default.content;
        }
        else if (itemText.series) {
            return itemText.series.default.content;
        }
        else if (itemText.program) {
            return itemText.program.default.content;
        }
        else if (itemText.collection) {
            return itemText.collection.default.content;
        }
    }
    return "";
};
var getCoverImageUrl = function (image) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    if (image) {
        if ((_a = image.tile["1.78"]) === null || _a === void 0 ? void 0 : _a.program) {
            return (_c = (_b = image.tile["1.78"]) === null || _b === void 0 ? void 0 : _b.program) === null || _c === void 0 ? void 0 : _c.default.url;
        }
        else if ((_d = image.tile["1.78"]) === null || _d === void 0 ? void 0 : _d.series) {
            return (_f = (_e = image.tile["1.78"]) === null || _e === void 0 ? void 0 : _e.series) === null || _f === void 0 ? void 0 : _f.default.url;
        }
        else if ((_g = image.tile["1.78"]) === null || _g === void 0 ? void 0 : _g.default) {
            return (_j = (_h = image.tile["1.78"]) === null || _h === void 0 ? void 0 : _h.default) === null || _j === void 0 ? void 0 : _j.default.url;
        }
    }
    return "";
};
var getTitleTreatment = function (image) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
    var imageStr = "";
    if (image) {
        if (image.title_treatment) {
            if ((_b = (_a = image.title_treatment) === null || _a === void 0 ? void 0 : _a["1.78"]) === null || _b === void 0 ? void 0 : _b.program) {
                imageStr = (_e = (_d = (_c = image.title_treatment) === null || _c === void 0 ? void 0 : _c["1.78"]) === null || _d === void 0 ? void 0 : _d.program) === null || _e === void 0 ? void 0 : _e.default.url;
            }
            else if ((_g = (_f = image.title_treatment) === null || _f === void 0 ? void 0 : _f["1.78"]) === null || _g === void 0 ? void 0 : _g.series) {
                imageStr = (_k = (_j = (_h = image.title_treatment) === null || _h === void 0 ? void 0 : _h["1.78"]) === null || _j === void 0 ? void 0 : _j.series) === null || _k === void 0 ? void 0 : _k.default.url;
            }
            else if ((_m = (_l = image.title_treatment) === null || _l === void 0 ? void 0 : _l["1.78"]) === null || _m === void 0 ? void 0 : _m.default) {
                imageStr = (_q = (_p = (_o = image.title_treatment) === null || _o === void 0 ? void 0 : _o["1.78"]) === null || _p === void 0 ? void 0 : _p.default) === null || _q === void 0 ? void 0 : _q.default.url;
            }
        }
        else if (image.logo) {
            if ((_s = (_r = image.logo) === null || _r === void 0 ? void 0 : _r["2.00"]) === null || _s === void 0 ? void 0 : _s.program) {
                imageStr = (_v = (_u = (_t = image.logo) === null || _t === void 0 ? void 0 : _t["2.00"]) === null || _u === void 0 ? void 0 : _u.program) === null || _v === void 0 ? void 0 : _v.default.url;
            }
            else if ((_x = (_w = image.logo) === null || _w === void 0 ? void 0 : _w["2.00"]) === null || _x === void 0 ? void 0 : _x.series) {
                imageStr = (_0 = (_z = (_y = image.logo) === null || _y === void 0 ? void 0 : _y["2.00"]) === null || _z === void 0 ? void 0 : _z.series) === null || _0 === void 0 ? void 0 : _0.default.url;
            }
            else if ((_2 = (_1 = image.logo) === null || _1 === void 0 ? void 0 : _1["2.00"]) === null || _2 === void 0 ? void 0 : _2.default) {
                imageStr = (_5 = (_4 = (_3 = image.logo) === null || _3 === void 0 ? void 0 : _3["2.00"]) === null || _4 === void 0 ? void 0 : _4.default) === null || _5 === void 0 ? void 0 : _5.default.url;
            }
        }
        imageStr = imageStr.replace("jpeg", "png");
        return imageStr;
    }
    return "";
};
var getBackground = function (image, width) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20;
    if (width === void 0) { width = 500; }
    var imageStr = "";
    if (image) {
        if (image.background_details) {
            if ((_b = (_a = image.background_details) === null || _a === void 0 ? void 0 : _a["1.78"]) === null || _b === void 0 ? void 0 : _b.program) {
                imageStr = (_e = (_d = (_c = image.background_details) === null || _c === void 0 ? void 0 : _c["1.78"]) === null || _d === void 0 ? void 0 : _d.program) === null || _e === void 0 ? void 0 : _e.default.url;
            }
            else if ((_g = (_f = image.background_details) === null || _f === void 0 ? void 0 : _f["1.78"]) === null || _g === void 0 ? void 0 : _g.series) {
                imageStr = (_k = (_j = (_h = image.background_details) === null || _h === void 0 ? void 0 : _h["1.78"]) === null || _j === void 0 ? void 0 : _j.series) === null || _k === void 0 ? void 0 : _k.default.url;
            }
            else if ((_m = (_l = image.background_details) === null || _l === void 0 ? void 0 : _l["1.78"]) === null || _m === void 0 ? void 0 : _m.default) {
                imageStr = (_q = (_p = (_o = image.background_details) === null || _o === void 0 ? void 0 : _o["1.78"]) === null || _p === void 0 ? void 0 : _p.default) === null || _q === void 0 ? void 0 : _q.default.url;
            }
        }
        else if (image.background) {
            if ((_s = (_r = image.background) === null || _r === void 0 ? void 0 : _r["1.78"]) === null || _s === void 0 ? void 0 : _s.program) {
                imageStr = (_v = (_u = (_t = image.background) === null || _t === void 0 ? void 0 : _t["1.78"]) === null || _u === void 0 ? void 0 : _u.program) === null || _v === void 0 ? void 0 : _v.default.url;
            }
            else if ((_x = (_w = image.background) === null || _w === void 0 ? void 0 : _w["1.78"]) === null || _x === void 0 ? void 0 : _x.series) {
                imageStr = (_0 = (_z = (_y = image.background) === null || _y === void 0 ? void 0 : _y["1.78"]) === null || _z === void 0 ? void 0 : _z.series) === null || _0 === void 0 ? void 0 : _0.default.url;
            }
            else if ((_2 = (_1 = image.background) === null || _1 === void 0 ? void 0 : _1["1.78"]) === null || _2 === void 0 ? void 0 : _2.default) {
                imageStr = (_5 = (_4 = (_3 = image.background) === null || _3 === void 0 ? void 0 : _3["1.78"]) === null || _4 === void 0 ? void 0 : _4.default) === null || _5 === void 0 ? void 0 : _5.default.url;
            }
        }
        else if (image.hero_collection) {
            if ((_7 = (_6 = image.hero_collection) === null || _6 === void 0 ? void 0 : _6["1.78"]) === null || _7 === void 0 ? void 0 : _7.program) {
                imageStr = (_10 = (_9 = (_8 = image.hero_collection) === null || _8 === void 0 ? void 0 : _8["1.78"]) === null || _9 === void 0 ? void 0 : _9.program) === null || _10 === void 0 ? void 0 : _10.default.url;
            }
            else if ((_12 = (_11 = image.hero_collection) === null || _11 === void 0 ? void 0 : _11["1.78"]) === null || _12 === void 0 ? void 0 : _12.series) {
                imageStr = (_15 = (_14 = (_13 = image.hero_collection) === null || _13 === void 0 ? void 0 : _13["1.78"]) === null || _14 === void 0 ? void 0 : _14.series) === null || _15 === void 0 ? void 0 : _15.default.url;
            }
            else if ((_17 = (_16 = image.hero_collection) === null || _16 === void 0 ? void 0 : _16["1.78"]) === null || _17 === void 0 ? void 0 : _17.default) {
                imageStr = (_20 = (_19 = (_18 = image.hero_collection) === null || _18 === void 0 ? void 0 : _18["1.78"]) === null || _19 === void 0 ? void 0 : _19.default) === null || _20 === void 0 ? void 0 : _20.default.url;
            }
        }
        imageStr = imageStr.replace("width=500", "width=".concat(width));
        return imageStr;
    }
    return "";
};
export { getTitleContent, getCoverImageUrl, getTitleTreatment, getBackground };
