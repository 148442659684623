var loadImage = function (src, className) {
    return new Promise(function (resolve, reject) {
        var img = document.createElement("img");
        img.src = src;
        img.classList.add(className);
        img.onload = function () { return resolve(img); };
        img.onerror = function () { return reject(new Error("Error loading image")); };
    });
};
export { loadImage };
