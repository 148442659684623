var GlobalState = /** @class */ (function () {
    function GlobalState() {
        this.selectedItem = null;
    }
    GlobalState.getInstance = function () {
        if (!GlobalState.instance) {
            GlobalState.instance = new GlobalState();
        }
        return GlobalState.instance;
    };
    return GlobalState;
}());
export default GlobalState.getInstance();
